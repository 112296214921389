export default {
  namespaced: true,
  state: {
    pendingRequests: 0
  },
  getters: {
    getLoading: state => {
      return state.pendingRequests > 0 ? true : false
    }
  },
  mutations: {
    setLoading(state, val) {
      state.pendingRequests += val ? 1 : -1
    }
  },
}
