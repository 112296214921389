import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import { auth } from '@/firebase'

Vue.use(VueRouter)

const routes = [
  {
    path: '/connexion',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '@/views/Login.vue')
  },
  {
    path: '/',
    name: 'Dashboard',
    component: () => import(/* webpackChunkName: "dashboard" */ '@/views/Dashboard.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/admin',
    name: 'Admin',
    component: () => import(/* webpackChunkName: "admin" */ '@/views/Admin.vue'),
    meta: {
      requiresAuth: true,
      requiresAdmin: true,
    }
  },
  { path: '/*', redirect: { name: 'Dashboard' }}
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(async (to, from, next) => {
  await Vue.nextTick();

  store.commit('loading/setLoading', 'beforeEach');

  const requiresAuth = to.matched.some(x => x.meta.requiresAuth)
  const requiresAdmin = to.matched.some(x => x.meta.requiresAdmin)
  const isAdmin = store.getters['user/isAdmin'];

  if (requiresAuth && !auth.currentUser) {
    next('/connexion')
  } else if (requiresAuth && requiresAdmin && !isAdmin) {
    next('/')
  } else {
    next()
  }

  store.commit('loading/setLoading', false);
})

export default router