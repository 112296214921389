import { filesCollection, storage } from '@/firebase'

export default {
  namespaced: true,
  state: {
    files: []
  },
  getters: {
    getFiles: state => {
      return state.files
    },
  },
  actions: {
    async fetchFiles({ commit }) {
      commit('loading/setLoading', 'fetchFiles', { root: true })
      filesCollection.get()
        .then((querySnapshot) => {
          let files = []
          querySnapshot.forEach(async (doc) => {
            try {
              let file = {
                uid: doc.id,
                ...doc.data(),
              }
              file.url = await storage.ref(`${file.slug}-${file.uid}.${file.ext}`).getDownloadURL()
              files.push(file)
            } catch {
              console.log('file not found')
            }
          })
          commit('setFiles', files)
          commit('loading/setLoading', false, { root: true })
        })
    },
    async uploadFile({ commit, dispatch }, { isExistingFile, data, file }) {
      commit('loading/setLoading', 'uploadFile', { root: true })
      if (isExistingFile) {
        await filesCollection.doc(isExistingFile.uid).update(data)

        if (file) {
          await storage.ref(`${isExistingFile.slug}-${isExistingFile.uid}.${isExistingFile.ext}`).delete()
          await storage.ref().child(`${data.slug}-${isExistingFile.uid}.${data.ext}`).put(file)
        }
      } else {
        const response = await filesCollection.add(data)
        await storage.ref().child(`${data.slug}-${response.id}.${data.ext}`).put(file)
      }
      dispatch('fetchFiles')
      commit('loading/setLoading', false, { root: true })
    },
    async deleteFile({ commit, dispatch }, file) {
      commit('loading/setLoading', 'deleteFile', { root: true })
      await filesCollection.doc(file.uid).delete()
      await storage.ref(`${file.slug}-${file.uid}.${file.ext}`).delete()
      dispatch('fetchFiles')
      commit('loading/setLoading', false, { root: true })
    }
  },
  mutations: {
    setFiles(state, val) {
      state.files = val
    }
  },
}