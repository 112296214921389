<template>
  <nav class="navbar" :class="{ active }">
    <a class="toggle" @click="active = !active">
      <font-awesome-icon :icon="['fas', 'bars']" />
    </a>
    <router-link to="/" class="brand">Chenoise</router-link>
    <div class="left">
      <!-- <a href="#" class="link">Link 1</a>
      <a href="#" class="link">Link 2</a> -->
    </div>
    <div class="right">
      <router-link v-if="isAdmin && !$route.meta.requiresAdmin" to="/admin" class="link">Administration</router-link>
      <router-link v-if="isAdmin && $route.meta.requiresAdmin" to="/" class="link">Retour à l'interface utilisateur</router-link>
      <a class="link" @click="logout">Déconnexion</a>
    </div>
  </nav>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: "Header",
  data() {
    return {
      active: false,
    }
  },
  computed: {
    ...mapGetters('user', ['isAdmin']),
  },
  methods: {
    ...mapActions('user', ['logout']),
  },
}
</script>

<style lang="scss" scoped>
header {
  padding: 1em;
}

.navbar {
  background: #e2e2e2;
  .toggle, .brand {
    display: inline-block;
  }
  .link {
    display: block;
    width: 100%;
    text-align: center;
    padding: 1em;
    text-decoration: none;
    background: #3490dc;
    color: white;
    transition: background .2s ease;
    cursor: pointer;
    white-space: nowrap;

    &:hover,&:active {
      background: #2779bd;
      color: white;
    }
  }
  .brand {
    width: 10em;
    text-align: center;
    padding: 1em;
    text-decoration: none;
    color: #22292f;
  }
  .toggle {
    padding: 1em;
    font-size: 17px;
    color: black;
    vertical-align: middle;
  }
}

@media (min-width: 48em) {
  .navbar {
    display: flex;
    .left,.right {
      display: flex;
      flex: 1;
    }
    .link {
        width: auto;
    }
    .right {
      justify-content: flex-end;
    }
    .toggle {
      display: none;
    }
  }
}

.navbar {
  div {
    display: none;
    @media (min-width: 48em) {
      display: flex;
    }
  }
}

@media (max-width: 48em) {
  .navbar.active {
    .toggle {
      background: #3490dc;
      color: white;
    }
    div {
      display: block;
    }
  }
}
</style>