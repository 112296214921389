<template>
  <div id="app">
    <Header v-if="$route.name !== 'Login'" />
    <router-view :key="$route.fullPath" />
    <div :class="{ loading: getLoading }" class="loader-container">
      <div class="loader"></div>
    </div>
  </div>
</template>

<script>
import Header from '@/components/Header'
import { mapGetters, mapMutations } from 'vuex'

export default {
  components: {
    Header,
  },
  computed: {
    ...mapGetters('loading', ['getLoading']),
  },
  methods: {
    ...mapMutations('layout', ['setInnerWidth'])
  },
  mounted() {
    document.addEventListener('serviceWorkerUpdated', () => {
      this.$toasted.show('Une nouvelle version est disponible', {
        position: "bottom-center",
        icon: {
          name: 'fas fa-sync-alt'
        },
        action: {
          text: 'Raffraichir la page',
          onClick: () => {
            window.location = `${window.location.href}?v=${Date.now()}`
          }
        },
      })
    })
    window.addEventListener('resize', () => {
      this.setInnerWidth(window.innerWidth)
    })
  },
  created() {
    this.setInnerWidth(window.innerWidth)
  }
}
</script>

<style lang="scss">
@import "@/assets/scss/modal";
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Lato', sans-serif;
}

body {
  background-color: #edf2f7 !important;
  overflow-x: hidden;
}

div.toasted {
  svg {
    margin-right: 15px;
  }

  a.action {
    text-align: center;
    margin: 0 0 0 7px !important;
  }
}

a.export-link {
  opacity: 0;
  visibility: none;
}
</style>

<style lang="scss" scoped>
div.loader-container {
  display: none;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0; bottom: 0;
  left: 0; right: 0;
  background-color: #edf2f7;
  z-index: 10;

  &.loading {
    display: flex;
  }

  .loader {
    margin: 60px auto;
    font-size: 10px;
    position: relative;
    text-indent: -9999em;
    border-top: 1.1em solid rgba(227, 227, 227, 0.4);
    border-right: 1.1em solid rgba(227, 227, 227, 0.4);
    border-bottom: 1.1em solid rgba(227, 227, 227, 0.4);
    border-left: 1.1em solid #2da4d0;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: load8 1.1s infinite linear;
    animation: load8 1.1s infinite linear;
    border-radius: 50%;
    width: 10em;
    height: 10em;

    &:after {
      border-radius: 50%;
      width: 10em;
      height: 10em;
    }
  }
}

@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
</style>
