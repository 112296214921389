export default {
  namespaced: true,
  state: {
    innerWidth: null,
  },
  getters: {
    getInnerWidth: state => {
      return state.innerWidth
    },
  },
  mutations: {
    setInnerWidth(state, val) {
      state.innerWidth = val
    },
  },
}