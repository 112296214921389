import { settingsCollection, storage } from '@/firebase'

export default {
  namespaced: true,
  state: {
    timeLimits: null,
    loginBackground: null,
  },
  getters: {
    getTimeLimits: state => {
      return state.timeLimits
    },
    getLoginBackground: state => {
      return state.loginBackground
    }
  },
  actions: {
    async fetchTimeLimits({ commit }) {
      commit('loading/setLoading', 'fetchTimeLimits', { root: true })
      const timeLimits = await settingsCollection.doc('time-limits').get()

      commit('setTimeLimits', timeLimits.data())
      commit('loading/setLoading', false, { root: true })
    },
    async fetchLoginBackground({ commit }) {
      commit('loading/setLoading', 'fetchFiles', { root: true })
      const response = await settingsCollection.doc('files').get()
      const files = response.data()
      commit('setLoginBackground', await storage.ref(files['login-background']).getDownloadURL())
      commit('loading/setLoading', false, { root: true })
    },
    async updateLoginBackground({ commit, dispatch }, file) {
      commit('loading/setLoading', 'fetchFiles', { root: true })
      const response = await settingsCollection.doc('files').get()
      const files = response.data()
      const fileName = file.name.replace(/\s+/g, '-').toLowerCase()
      await settingsCollection.doc('files').update({
        'login-background': fileName
      })
      await storage.ref(files['login-background']).delete()
      await storage.ref().child(fileName).put(file)
      dispatch('fetchLoginBackground')
      commit('loading/setLoading', false, { root: true })
    },
    async setTimeLimits({ dispatch }, data) {
      await settingsCollection.doc('time-limits').update(data)
      dispatch('fetchTimeLimits')
    }
  },
  mutations: {
    setTimeLimits(state, val) {
      state.timeLimits = val
    },
    setLoginBackground(state, val) {
      state.loginBackground = val
    }
  },
}