import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate"

import user from './modules/user'
import loading from './modules/loading'
import kids from './modules/kids'
import schoolyears from './modules/schoolyears'
import settings from './modules/settings'
import files from './modules/files'
import layout from './modules/layout'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    user,
    loading,
    kids,
    schoolyears,
    settings,
    files,
    layout
  },
  plugins: [
    createPersistedState({
      paths: ['user']
    })
  ],
})
