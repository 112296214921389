import { auth, usersCollection } from '@/firebase'
import router from '@/router/index'

export default {
  namespaced: true,
  state: {
    userProfile: null,
    users: [],
  },
  getters: {
    getUserProfile: state => {
      return state.userProfile
    },
    getUsers: state => {
      return state.users
    },
    isAdmin: state => {
      return !!state.userProfile?.admin
    }
  },
  actions: {
    async login({ commit, dispatch }, form) {
      commit('loading/setLoading', 'login', { root: true })
      try {
        await auth.signInWithEmailAndPassword(form.email, form.password)

        dispatch('fetchUserProfile')

        router.push('/')
        commit('loading/setLoading', false, { root: true })  
      } catch(e) {
        commit('loading/setLoading', false, { root: true })  
        return e
      }
    },
    async signup({ commit, dispatch }, form) {
      commit('loading/setLoading', 'signup', { root: true })
      try {
        const { user } = await auth.createUserWithEmailAndPassword(form.email, form.password)
        
        user.updateProfile({
          displayName: `${form.firstname} ${form.lastname}`
        }).then(() => {
          user.sendEmailVerification()
        })
  
        await usersCollection.doc(user.uid).set({
          firstname: form.firstname,
          lastname: form.lastname,
        })
      
        dispatch('fetchUserProfile')
  
        router.push('/')
        commit('loading/setLoading', false, { root: true })
      } catch(e) {
        commit('loading/setLoading', false, { root: true })  
        return e
      }
    },
    async addNotificationToken({ commit, dispatch }, token) {
      commit('loading/setLoading', 'fetchUserProfile', { root: true })
      const user = auth.currentUser
      await usersCollection.doc(user.uid).update({
        fcm: token
      })
      dispatch('fetchUserProfile')
      commit('loading/setLoading', false, { root: true })
    },
    async fetchUserProfile({ commit }) {
      commit('loading/setLoading', 'fetchUserProfile', { root: true })
      const user = auth.currentUser
      const userProfile = await usersCollection.doc(user.uid).get()
      
      commit('setUserProfile', {
        ...userProfile.data(),
        emailVerified: user.emailVerified,
        uid: user.uid,
      })
      commit('loading/setLoading', false, { root: true })
    },
    async fetchUsers({ commit }) {
      commit('loading/setLoading', 'fetchUsers', { root: true })

      usersCollection
        .get()
        .then(querySnapshot => {
          let users = []
          querySnapshot.forEach(doc => {
            let user = {
              uid: doc.id,
              ...doc.data(),
            }
            users.push(user)
          })
          commit('setUsers', users)
          commit('loading/setLoading', false, { root: true })
        })
    },
    async logout({ commit }) {
      await auth.signOut()
    
      commit('setUserProfile', null)
      router.push('/connexion')
    },
  },
  mutations: {
    setUserProfile(state, val) {
      state.userProfile = val
    },
    setUsers(state, val) {
      state.users = val
    }
  },
}
