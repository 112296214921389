import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/storage'
// import 'firebase/messaging'

// firebase init - add your own config here
const firebaseConfig = {
  apiKey: process.env.VUE_APP_API_KEY,
  authDomain: process.env.VUE_APP_AUTH_DOMAIN,
  projectId: process.env.VUE_APP_PROJECT_ID,
  storageBucket: process.env.VUE_APP_STORAGE_BUCKET,
  messagingSenderId: Number(process.env.VUE_APP_MESSAGING_SENDER_ID),
  appId: process.env.VUE_APP_APP_ID
};
const app = firebase.initializeApp(firebaseConfig)

// utils
const db = app.firestore()
const auth = app.auth()
const storage = app.storage()
// const messaging = app.messaging()
const FieldValue = firebase.firestore.FieldValue

// collection references
const usersCollection = db.collection('users')
const kidsCollection = db.collection('kids')
const schoolYearsCollection = db.collection('schoolyears')
const settingsCollection = db.collection('settings')
const filesCollection = db.collection('files')
const logsCollection = db.collection('logs')

// export utils/refs
export {
  db,
  auth,
  storage,
  // messaging,
  FieldValue,
  usersCollection,
  kidsCollection,
  schoolYearsCollection,
  settingsCollection,
  filesCollection,
  logsCollection,
}
