import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { auth } from './firebase'
import Toasted from 'vue-toasted'
import VueLodash from 'vue-lodash'
import lodash from 'lodash'

import './plugins/VueFontawesome'
import './registerServiceWorker'
import '@codolog/form/dist/form.min.css'
import 'vue-search-select/dist/VueSearchSelect.css'

Vue.use(Toasted, { iconPack: 'custom-class' })
Vue.use(VueLodash, { lodash: lodash })
Vue.config.productionTip = false

Vue.filter('capitalize', function (value) {
  if (!value) return ''
  value = value.toString()
  return value.charAt(0).toUpperCase() + value.slice(1)
})

store.dispatch('settings/fetchLoginBackground')

let app
auth.onAuthStateChanged(async user => {
  if (user) {
    store.dispatch('user/fetchUserProfile')
    store.dispatch('settings/fetchTimeLimits')
    store.dispatch('files/fetchFiles')
    // const swRegistration = await navigator.serviceWorker.register(`./firebase-sw-${process.env.VUE_APP_MODE}.js`);
    // messaging
    //   .getToken({
    //     vapidKey: process.env.VUE_APP_VAPID_KEY,
    //     serviceWorkerRegistration: swRegistration,
    //   })
    //   .then(currentToken => {
    //     if (currentToken) {
    //       store.dispatch('user/addNotificationToken', currentToken)
    //     } else {
    //       console.log('No registration token available. Request permission to generate one.');
    //     }
    //   })
  }

  if (!app) {
    app = new Vue({
      router,
      store,
      render: h => h(App),
      created() {
        document.title = 'Périscolaire'
      }
    }).$mount('#app')
  }
})